import type { UseFetchOptions } from '#app'
import type { MaybeRefOrGetter } from 'vue'
import { defu } from 'defu'
import type {
  ApiOptionals,
  ApiResponse,
  ApiResponsePaginated,
  PaginationParams,
  UseApiFetchOptions,
} from '~/types/api.type'
import type { Client } from '~/types/client.type'
import type { MappedOmit } from '~/types/common.type'
import type { Employee } from '~/types/employee.type'
import type { NotificationThreshold } from '~/types/notification-threshold.type'
import type { Project, ProjectBillingType } from '~/types/project.type'
import type { Tool } from '~/types/tool.type'

export type ApiProjectGetListParams = {
  isActive?: boolean
  clientId?: number
  searchTerm?: string
  withOwnTimeSpentSec?: boolean
  withTimeSpentSec?: boolean
  withOutstandingInvoiceAmount?: boolean
  additionalProjects?: Project['id'][]
} & PaginationParams<Project> & ApiProjectGetByIdParams

export type ApiProjectGetListParamsUnPaginated = {
  isActive?: boolean
  clientId?: number
  noPagination?: true
  additionalProjects?: Project['id'][]
} & ApiProjectGetByIdParams

export interface ApiProjectGetByIdParams {
  withTaskLists?: boolean
  withTimeEntries?: boolean
  withNotificationThresholds?: boolean
  withClient?: boolean
  withTools?: boolean
  withLeads?: boolean
}

export type ApiProjectCreateProject = MappedOmit<Project, 'id' | 'createdAt' | 'client' | 'tools' | 'leads' | 'notificationThresholds' | 'ownTimeSpentSec' | 'taskLists' | 'timeSpentSec' | 'billingType' | 'sumPrice' | 'timeLimitSec' | 'active' | 'outstandingInvoiceAmount'> & {
  clientId?: Client['id']
  leads?: Employee['id'][]
  tools?: Tool['id'][]
  billingType?: ProjectBillingType
  notificationThresholds?: NotificationThreshold[]
  active?: Project['active']
  timeLimitSec?: Project['timeLimitSec']
}

export function useApiProjectGetList<T extends ApiProjectGetListParams>(params: MaybeRefOrGetter<T> = {} as T, options: UseApiFetchOptions<ApiResponsePaginated<Project<ApiOptionals<T>>>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponsePaginated<Project<ApiOptionals<T>>>> = { method: 'GET', params }
  return useApiFetch('/projects', defu(notOverridableOptions, options))
}

export function useApiProjectGetListUnpaginated<T extends ApiProjectGetListParamsUnPaginated>(params: MaybeRefOrGetter<T> = {} as T, options: UseApiFetchOptions<ApiResponse<Project<ApiOptionals<T>>[]>> = {}) {
  const paramsWithNoPagination = computed<T>(() => {
    return { ...toValue(params), noPagination: true }
  })
  const notOverridableOptions: UseFetchOptions<ApiResponse<Project<ApiOptionals<T>>[]>> = { method: 'GET', params: paramsWithNoPagination }
  return useApiFetch('/projects', defu(notOverridableOptions, options))
}

export function useApiProjectGetById<T extends ApiProjectGetByIdParams>(id: MaybeRefOrGetter<Project['id']>, params: MaybeRefOrGetter<T> = {} as T, options: UseFetchOptions<ApiResponse<Project<ApiOptionals<T>>>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<Project<ApiOptionals<T>>>> = { method: 'GET', params }
  return useApiFetch(computed(() => `/projects/${toValue(id)}`), defu(notOverridableOptions, options))
}

type ApiProjectResource = Project<'withTaskLists' | 'withClient' | 'withLeads' | 'withTools'>

export function useApiProjectCreate(project: MaybeRefOrGetter<ApiProjectCreateProject>, options: UseFetchOptions<ApiResponse<ApiProjectResource>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<ApiProjectResource>> = { method: 'POST', body: project }
  const overridableOptions: UseFetchOptions<ApiResponse<ApiProjectResource>> = { immediate: false, watch: false }
  return useApiFetch('/projects', defu(notOverridableOptions, options, overridableOptions))
}

export function useApiProjectUpdateById(id: MaybeRefOrGetter<Project['id']>, project: MaybeRefOrGetter<Partial<ApiProjectCreateProject>>, options: UseFetchOptions<ApiResponse<ApiProjectResource>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<ApiProjectResource>> = { method: 'PATCH', body: project }
  const overridableOptions: UseFetchOptions<ApiResponse<ApiProjectResource>> = { immediate: false, watch: false }
  return useApiFetch(() => `/projects/${id}`, defu(notOverridableOptions, options, overridableOptions))
}

export function useApiProjectDeleteById(id: MaybeRefOrGetter<Project['id']>, options: UseFetchOptions<never> = {}) {
  const notOverridableOptions: UseFetchOptions<never> = { method: 'DELETE' }
  const overridableOptions: UseFetchOptions<never> = { immediate: false, watch: false }
  return useApiFetch(() => `/projects/${toValue(id)}`, defu(notOverridableOptions, options, overridableOptions))
}
